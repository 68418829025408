<template>
  <v-row class="h-100 flex-md-nowrap" no-gutters data-cy="brand-details">
    <tree-navigation :key="item.id" :tree-id="item.id" :country-code="getMarket" />
    <v-col>
      <breadcrumb-nav :items="breadcrumbs" />
      <nv-page :header="item.name">
        <template slot="toolbar">
          <v-spacer />
          <nv-btn type="back" />
          <v-btn
            v-if="$auth.hasPermissions('owl.marketview')"
            color="accent"
            data-cy="full-transfer-btn"
            @click="transferForm.show = true"
          >
            <v-icon class="mr-1">mdi-transfer</v-icon>
            <span>Full Transfer</span>
          </v-btn>
          <nv-btn
            v-if="item.status != 'archived' && $auth.hasPermissions('owl.marketview')"
            type="edit"
            @click="form.show = true"
          />
          <v-btn
            v-if="item.status == 'archived' && $auth.hasPermissions('owl')"
            color="#ff9800"
            @click="reactivateDialogue()"
          >
            <v-icon class="mr-1">mdi-update</v-icon>
            <span>Reactivate</span>
          </v-btn>
        </template>
        <v-card class="pa-4" data-cy="details-card">
          <v-row dense>
            <nv-show cols="12" lg="1" md="2" sm="6" header="Level">Brand</nv-show>
            <nv-show ref="name" cols="12" lg="3" md="3" sm="6" header="Name">
              {{ item.name }}
            </nv-show>
            <nv-show cols="12" lg="2" md="2" sm="6" header="Tree ID">{{ item.id }}</nv-show>
            <nv-show ref="parent" cols="12" lg="3" md="3" sm="6" header="Parent">
              {{ item.parent.name }}
            </nv-show>
            <nv-show ref="status" cols="12" lg="1" md="2" sm="6" header="Status">
              {{ item.status | humanize }}
            </nv-show>
            <nv-show ref="country_code" cols="12" lg="1" md="2" sm="6" header="Market">
              {{ getMarket }}
            </nv-show>
            <nv-show cols="12" lg="6" md="12" sm="6" header="VCIDs" no-placeholder>
              <vcid-pane v-if="item.id" :value="item.id" no-add @saved="fetchData" />
            </nv-show>
            <nv-show ref="category" cols="12" lg="3" sm="6" header="Category">
              {{ item.sub_category ? item.sub_category.category.name : null }}
            </nv-show>
            <nv-show ref="subcategory" cols="12" lg="2" sm="6" header="Subcategory">
              {{ item.sub_category ? item.sub_category.name : null }}
            </nv-show>
          </v-row>
        </v-card>
        <v-card class="mt-4 elevation-2" data-cy="tabs-card">
          <v-tabs v-model="tabs" slider-color="accent">
            <v-tab data-cy="channels-tab"> Channels </v-tab>
            <v-tab data-cy="apps-tab"> Applications </v-tab>
            <v-tab data-cy="localization-tab"> Relabel </v-tab>
            <v-tab data-cy="history-tab"> History </v-tab>
            <v-tab data-cy="patterns-tab"> Rules </v-tab>
          </v-tabs>
          <v-tabs-items v-if="item.id != null" v-model="tabs">
            <v-tab-item data-cy="channels-pane">
              <channels-tab-list
                ref="channels-tab-list"
                :value="item.id"
                :can-add="item.status === 'active'"
                :country-code="getMarket"
              />
            </v-tab-item>
            <v-tab-item data-cy="apps-pane">
              <app-dictionary-tab :child-id="item.id" :classifiable="item.classifyable" />
            </v-tab-item>
            <v-tab-item data-cy="localization-pane">
              <localization-tab-list :id="item.id" ref="localization-tab-list" type="brands" />
            </v-tab-item>
            <v-tab-item data-cy="history-pane">
              <proposals-history :id="item.id" ref="history-list" type="children" />
            </v-tab-item>
            <v-tab-item data-cy="patterns-pane">
              <patterns-tab
                :id="item.id"
                ref="patterns-list"
                :classifiable="item.classifyable"
                kind="tree"
                :country-code="getMarket"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card>

        <brand-channel-form
          v-model="form.show"
          :editing="item"
          :country-code="getMarket"
          @saved="fetchData"
        />
        <transfer-form v-if="item.id" v-model="transferForm.show" :child-id="item.id" />
        <nv-confirm ref="confirm" />
      </nv-page>
    </v-col>
  </v-row>
</template>

<script>
import HTTP from '@/api/owl';
import ChannelsTabList from '@/components/mv/brand/ChannelsTabList.vue';
import ProposalsHistory from '@/components/mv/ProposalsHistory';
import BrandChannelForm from '@/components/mv/BrandChannelForm';
import BreadcrumbNav from '@/components/mv/BreadcrumbNav';
import TreeNavigation from '@/components/mv/TreeNavigation';
import VcidPane from '@/components/mv/VcidPane';
import AppDictionaryTab from '@/components/mv/AppDictionaryTab';
import LocalizationTabList from '@/components/mv/parents/LocalizationTabList';
import PatternsTab from '@/components/mv/PatternsTab.vue';
import TransferForm from '@/components/mv/TransferForm';

export default {
  components: {
    TransferForm,
    LocalizationTabList,
    AppDictionaryTab,
    VcidPane,
    TreeNavigation,
    BreadcrumbNav,
    BrandChannelForm,
    ChannelsTabList,
    ProposalsHistory,
    PatternsTab,
  },
  data: () => ({
    item: {
      id: null,
      parent: {},
    },
    tabs: null,
    form: {
      show: false,
      edit: null,
    },
    breadcrumbs: [],
    filtersParam: 'brand.ft',
    transferForm: {
      show: false,
    },
    reactivateForm: {
      show: false,
    },
  }),
  computed: {
    getMarket() {
      return this.$route.query.countryCode == '*' ? 'Default' : this.$route.query.countryCode;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let params = { country_code: this.$route.query.countryCode };
      HTTP.get(`/brands/${this.$route.params.childId}`, { params }).then(({ data }) => {
        this.item = data;
        if (data.parent) {
          this.breadcrumbs = [
            {
              text: data.parent.name,
              disabled: false,
              to: {
                name: 'owl.parents.show',
                params: {
                  parentId: data.parent.id,
                },
                query: {
                  countryCode: this.$route.query.countryCode,
                },
              },
            },
            {
              text: data.name,
              disabled: true,
            },
          ];
        }
      });
    },
    reactivateDialogue() {
      this.$refs.confirm
        .open(`You are trying to reactivate the ${this.item.type} "${this.item.name}"`)
        .then(() => {
          HTTP.put(`/admin/reactivate/${this.item.type}`, { id: this.item.id })
            .then(() => {
              this.$flash('success', 'Successful');
            })
            .catch(err => {
              this.$flash('error', err.toString());
            });
        });
    },
  },
};
</script>
