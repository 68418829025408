<template>
  <v-col>
    <v-row align="start" no-gutters>
      <v-col cols="4">
        <v-text-field
          ref="find"
          v-model.number="treeId"
          :rules="rules.find"
          :loading="loading"
          :disabled="loading"
          label="Find by Tree ID"
          flat
          solo
          hide-details="auto"
          append-icon="mdi-send-circle-outline"
          autocomplete="off"
          data-cy="find-box"
          @click:append="findTree"
          @keydown.enter="findTree"
        />
      </v-col>
      <v-col cols="3" class="pl-4">
        <v-select
          v-model="localization"
          :items="filter_localization"
          label="Select a localization"
          outlined
          :default="'default'"
          height="40"
        />
      </v-col>
      <v-col cols="5" class="mt-n5 pl-4">
        <v-list-item v-if="!loading" data-cy="find-result">
          <template v-if="hasContent">
            <search-list-avatar :type="tree.type" />
            <v-list-item-content>
              <search-list-content :item="tree" :link="true" :country-code="localization" />
            </v-list-item-content>
          </template>
          <template v-else>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-list-item-avatar class="ml-n6" v-bind="attrs" v-on="on">
                  <v-icon small>
                    mdi-help-circle-outline
                  </v-icon>
                </v-list-item-avatar>
              </template>
              <span>No content Available for this location try using some other location</span>
            </v-tooltip>
          </template>
        </v-list-item>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import HTTP from '@/api/owl-search';
import Rules from 'bsm-validation-rules';
import SearchListAvatar from '@/components/mv/SearchListAvatar';
import SearchListContent from '@/components/mv/SearchListContent';

const rules = {
  find: [Rules.typeOf('integer'), Rules.numericality({ min: 0 })],
};

export default {
  components: { SearchListContent, SearchListAvatar },
  data: () => ({
    treeId: null,
    tree: null,
    loading: false,
    localization: '*',
    filter_localization: [
      { text: 'default', value: '*' },
      { text: 'us', value: 'us' },
    ],
    rules,
    hasContent: false,
  }),
  methods: {
    clearTree() {
      this.tree = null;
      this.hasContent = false;
    },
    findTree() {
      if (!this.$refs.find.validate()) {
        return;
      }
      if (!this.treeId) {
        this.tree = null;
        this.hasContent = false;
        return;
      }
      this.loading = true;
      let params = { id: this.treeId, country_code: this.localization };
      HTTP.get('/tree/find', { params })
        .then(({ data }) => {
          if (data[0] == null) {
            this.clearTree();
            this.$flash('error', `${this.treeId} ID is unavailable`);
            this.hasContent = false;
          } else {
            this.tree = data[0];
            this.hasContent = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
