<template>
  <nv-tab-page>
    <template #toolbar>
      <nv-btn
        v-if="$auth.hasPermissions('owl.marketview') && canAdd"
        type="add"
        @click="openForm()"
      />
      <v-spacer />
      <nv-table-search v-model="search" />
    </template>

    <template #filters>
      <nv-table-filters
        ref="applied-filters"
        :value="tableFilters"
        @clear="$refs['filter-sheet'].clearFilters()"
      >
        <filter-sheet ref="filter-sheet" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      class="elevation-1"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.id }}</td>
          <td>
            <router-link :to="brandPath(item.id)">
              {{ item.name }}
            </router-link>
          </td>
          <td>{{ item.status | humanize }}</td>
          <td>{{ countryCode }}</td>
        </tr>
      </template>
    </v-data-table>

    <brand-channel-form v-model="form.show" :post-endpoint="postEndpoint" @saved="tableFetch" />
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/owl';
import BrandChannelForm from '@/components/mv/BrandChannelForm';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import FilterSheet from '@/components/mv/brand/FilterSheet';
import { getCountryByValue } from '@/helpers/filters';

const headers = [
  { text: 'ID', sortable: true, value: 'id' },
  { text: 'Name', sortable: true, value: 'name' },
  { text: 'Status', sortable: false, value: 'status' },
  { text: 'Market', sortable: false, value: 'market' },
];

export default {
  components: { FilterSheet, BrandChannelForm },
  mixins: [TableMixin],
  props: {
    value: { type: Number, required: true },
    canAdd: { type: Boolean, required: true },
    countryCode: { type: String, required: true },
  },
  data: () => ({
    headers,
    form: {
      show: false,
    },
    tableParam: 'brands.dt',
    searchParam: 'brands.q',
    postEndpoint: undefined,
    table: {
      sortBy: ['name'],
      sortDesc: [false],
    },
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(
        HTTP,
        `/parents/${this.value}/brands?country_code=${getCountryByValue(this.countryCode)}`,
      );
    },
    brandPath(childId) {
      return {
        name: 'owl.brands.show',
        params: { childId },
        query: { countryCode: getCountryByValue(this.countryCode) },
      };
    },
    openForm() {
      this.postEndpoint = `/parents/${this.value}/brand?country_code=${getCountryByValue(
        this.countryCode,
      )}`;
      this.form.show = true;
    },
  },
};
</script>
