<template>
  <tr :class="{ 'blue-grey darken-2': processed }">
    <td colspan="3">
      <v-form ref="form">
        <v-row align="center" class="my-0">
          <v-col cols="4" class="text-truncate">
            <a
              :href="'https://play.google.com/store/apps/details?id=' + model.pattern"
              target="_blank"
              class="text-subtitle-1"
            >
              {{ model.pattern }}
            </a>
          </v-col>
          <v-col cols="2" class="text-truncate">
            <v-menu v-if="item.app_names.length > 1" offset-y max-height="300">
              <template #activator="{ on }">
                <a class="white--text" v-on="on">
                  {{ item.app_names[0] }}
                  <v-icon dense>mdi-chevron-down</v-icon>
                </a>
              </template>
              <v-list dense>
                <v-list-item v-for="(name, index) in item.app_names" :key="index" dense>
                  <v-list-item-title>{{ name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <span v-else>{{ item.app_names[0] }}</span>
          </v-col>
          <v-col cols="4">
            <app-dict-search
              v-model="model.app_dictionary_id"
              :rules="rules.appId"
              :disabled="processed || loading"
              inline
            />
          </v-col>
          <nv-show :header="model.effective_start_date ? 'Start Date' : ''" cols="1" no-placeholder>
            <owl-date-picker
              v-model="model.effective_start_date"
              :disabled="processed || loading"
              label="Start Date"
              icon
              clearable
            />
            <span v-if="model.effective_start_date" class="mr-1">
              {{ model.effective_start_date }}
            </span>
          </nv-show>
          <v-col
            v-if="item.locked_by === $auth.user.email"
            cols="1"
            class="d-flex justify-end align-center"
          >
            <template v-if="onTime(item.lock_expires_at)">
              <save-btn v-if="!processed" :loading="loading" class="ml-4" @click="save()" />
              <done-chip v-else />
            </template>
            <expire-chip v-else />
          </v-col>
        </v-row>
      </v-form>
    </td>
  </tr>
</template>

<script>
import AppDictSearch from '@/components/mv/AppDictSearch';
import ClassifyRowMixin from '@/components/cls/ClassifyRowMixin';
import OwlDatePicker from '@/components/OwlDatePicker';
import Rules from 'bsm-validation-rules';
import SaveBtn from '@/components/cls/SaveBtn.vue';
import DoneChip from '@/components/cls/DoneChip.vue';
import ExpireChip from '@/components/cls/ExpireChip.vue';

const rules = {
  appId: [Rules.presence()],
};

export default {
  components: { ExpireChip, DoneChip, SaveBtn, OwlDatePicker, AppDictSearch },
  mixins: [ClassifyRowMixin],
  data() {
    return {
      model: {
        pattern: this.item.package_id,
        app_dictionary_id: null,
        effective_start_date: null,
      },
      formattedPayload: {},
      rules: {
        appId: [],
      },
    };
  },
  methods: {
    save() {
      this.rules = rules;
      this.formattedPayload = this.model;
      this.submitClassify(`/android/discoveries/${this.item.id}/rules`);
    },
  },
};
</script>
