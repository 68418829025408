<template>
  <v-list-item-content>
    <v-list-item-title>
      <router-link v-if="link" :to="showLink()">
        {{ item.name }}
      </router-link>
      <span v-else>{{ item.name }}</span>
    </v-list-item-title>
    <v-list-item-subtitle ref="subtitle" v-placeholder :title="isOverflowing ? fullName() : null">
      <template v-if="!!item.brand">
        {{ item.brand.parent.name }}
        <v-icon small>mdi-chevron-right</v-icon>
      </template>
      <template v-if="!!item.parent || !!item.brand">
        {{ item.parent ? item.parent.name : item.brand.name }}
      </template>
      <div
        v-if="showLocalizations && item.localizations && item.localizations.length > 0"
        class="text-caption"
      >
        Relabel: {{ stringifyLocalizations(item.localizations) }}
      </div>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    link: {
      type: Boolean,
      default: false,
    },
    countryCode: {
      type: String,
      required: false,
      default: '*',
    },
    showLocalizations: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOverflowing: false,
  }),
  mounted() {
    const el = this.$refs.subtitle;
    this.isOverflowing = el && el.scrollWidth > el.offsetWidth;
  },
  methods: {
    showLink() {
      const param = this.item.type === 'parent' ? 'parentId' : 'childId';
      const query = 'countryCode';
      return {
        name: `owl.${this.item.type}s.show`,
        params: {
          [param]: this.item.display_id,
        },
        query: {
          [query]: this.item.country_codes[0] || this.countryCode,
        },
      };
    },
    fullName() {
      if (this.item.brand != null) {
        return `${this.item.brand.parent.name} > ${this.item.brand.name}`;
      } else if (this.item.parent != null) {
        return this.item.parent.name;
      }
      return '';
    },
    stringifyLocalizations(localizations) {
      return localizations.map(l => `${l.name} (${l.country_code})`).join(', ');
    },
  },
};
</script>
