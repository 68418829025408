import HTTP from '@/api/ant';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import SearchListContent from '@/components/mv/SearchListContent';
import SearchListAvatar from '@/components/mv/SearchListAvatar';
import FilterSheet from '@/components/cls/FilterSheet';
import { fetchTreeSearch, fetchAppsSearch } from '@/helpers/owlFind.js';

export default function(type) {
  return {
    components: { SearchListContent, SearchListAvatar, FilterSheet },
    mixins: [TableMixin],
    data: () => ({
      countries: [],
      extendedItems: [],
      form: {
        show: false,
        edit: null,
      },
    }),
    methods: {
      tableFetch() {
        this.paginateTableData(HTTP, `/${type}/rules`)
          .then(async ({ data }) => {
            if (data.length) {
              this.loading = true;
              const groupedData = data.reduce((acc, item) => {
                if (!acc[item.localization]) {
                  acc[item.localization] = [];
                }
                acc[item.localization].push(item);
                return acc;
              }, {});
              if (type === 'android' || type === 'ios') {
                const promises = Object.entries(groupedData).map(([countryCode, items]) =>
                  fetchAppsSearch(items, countryCode).then(result => ({ countryCode, result })),
                );

                // Wait for all calls to complete
                const results = await Promise.all(promises);

                // Combine all results
                this.extendedItems = results.reduce((acc, { result }) => {
                  return acc.concat(result);
                }, []);
              } else {
                const promises = Object.entries(groupedData).map(([countryCode, items]) =>
                  fetchTreeSearch(items, countryCode).then(result => ({ countryCode, result })),
                );
                const results = await Promise.all(promises);

                this.extendedItems = results.reduce((acc, { result }) => {
                  return acc.concat(result);
                }, []);
              }
              this.extendedItems.sort((a, b) => {
                return new Date(b.updated_at) - new Date(a.updated_at);
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      openForm(item) {
        this.form.edit = item;
        this.form.show = true;
      },
    },
  };
}
