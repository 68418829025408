<template>
  <nv-page header="Video Reviews" data-cy="video-reviews-list">
    <template #toolbar>
      <v-row align="center">
        <v-col cols="3">
          <bulk-approve-btn
            v-if="showSelect"
            :disabled="!selected.length"
            :loading="approveLoading"
            @click="bulkApprove()"
          />
        </v-col>
        <v-col cols="4">
          <filter-sheet ref="filter-sheet" @changed="setTableSettings" />
        </v-col>
        <v-col cols="5">
          <nv-table-search v-model="search" sync class="ml-4" />
        </v-col>
      </v-row>
    </template>

    <template #filters>
      <nv-table-filters
        ref="applied-filters"
        :value="tableFilters"
        @clear="$refs.filters.clearFilters()"
      >
        <reviews-filter-sheet v-if="isAll" ref="filters" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      v-model="selected"
      :headers="videoHeaders"
      :items="extendedItems"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      :show-select="showSelect"
      must-sort
    >
      <template #item="{ item, isSelected, select }">
        <review-row
          :item="item"
          :show-select="showSelect"
          video
          @open-form="openForm"
          @open-discoveries="openDiscoveries"
        >
          <template #select>
            <v-simple-checkbox v-ripple :value="isSelected" @input="select($event)" />
          </template>
        </review-row>
      </template>
    </v-data-table>
    <cls-review-form
      v-if="form.namespace"
      :id="form.id"
      v-model="form.show"
      :namespace="form.namespace"
      @saved="tableFetch"
    />
    <discoveries-dialog v-model="discoveries.show" :proposal-id="discoveries.proposalId" />
  </nv-page>
</template>

<script>
import ReviewsList from '@/components/cls/ReviewsList';
import ReviewRow from '@/components/ReviewRow';
import FilterSheet from '@/components/mv/proposals/FilterSheet';
import ClsReviewForm from '@/components/cls/ReviewForm';
import DiscoveriesDialog from '@/components/cls/video/DiscoveriesDialog';
import ReviewsFilterSheet from '@/components/cls/ReviewsFilterSheet.vue';
import BulkApproveBtn from '@/components/cls/BulkApproveBtn.vue';

const videoHeaders = [
  { text: 'Type', sortable: false, value: 'item_kind' },
  { text: 'Name', sortable: false, value: 'name' },
  { text: 'Level', sortable: false, value: 'channel' },
  { text: 'Discoveries', sortable: false, value: 'video' },
  { text: 'Market', sortable: false, value: 'proposed_attributes.country' },
  { text: 'Status', sortable: false, value: 'status' },
  { text: 'Created By', sortable: false, value: 'created_by' },
  { text: 'Updated At', sortable: true, value: 'updated_at' },
];
export default {
  components: {
    BulkApproveBtn,
    ReviewsFilterSheet,
    DiscoveriesDialog,
    ClsReviewForm,
    FilterSheet,
    ReviewRow,
  },
  extends: ReviewsList('video'),
  data: () => ({
    videoHeaders,
    discoveries: {
      show: false,
      proposalId: null,
    },
  }),
  methods: {
    openDiscoveries(id) {
      this.discoveries.proposalId = id;
      this.discoveries.show = true;
    },
  },
};
</script>
