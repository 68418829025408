<template>
  <tr :class="{ 'blue-grey darken-2': processed }">
    <td colspan="4">
      <v-form ref="form">
        <v-row align="center" class="my-0">
          <v-col cols="4">
            <v-text-field
              ref="pattern"
              v-model="model.pattern"
              :rules="rules.pattern"
              :disabled="processed || loading"
              label="Pattern"
              hide-details="auto"
              solo
              flat
              data-cy="pattern"
            />
          </v-col>
          <v-col cols="4">
            <tree-search
              v-model="model.tree_id"
              :rules="rules.treeId"
              :disabled="processed || loading"
              mode="classification"
              inline
            />
          </v-col>
          <v-col cols="2">
            <v-select
              ref="property"
              v-model="model.property"
              :items="ottmProperties"
              :rules="rules.property"
              :disabled="processed || loading"
              placeholder="Property"
              solo
              flat
              hide-details="auto"
              data-cy="property"
            />
          </v-col>
          <nv-show :header="model.effective_start_date ? 'Start Date' : ''" cols="1" no-placeholder>
            <v-btn icon small :disabled="processed || loading" @click="addLocalization">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <owl-date-picker
              v-model="model.effective_start_date"
              :disabled="processed || loading"
              label="Start Date"
              icon
              clearable
            />
            <span v-if="model.effective_start_date" class="mr-1">
              {{ model.effective_start_date }}
            </span>
          </nv-show>
          <v-col cols="1" class="d-flex justify-end align-center">
            <save-btn v-if="!processed" :loading="loading" class="ml-4" @click="save()" />
            <done-chip v-else class="ml-2" />
          </v-col>
        </v-row>
        <v-row v-for="(row, index) in localizations" :key="index" align="center" class="my-0">
          <v-col cols="4" lg="7">
            <tree-search
              v-model="row.tree_id"
              :rules="treeSearchRules"
              :disabled="processed || loading"
              mode="classification"
              inline
              :country-code="getCountry(row.country)"
            />
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="row.country"
              :items="filteredCountries"
              label="Country"
              :disabled="processed || loading"
            ></v-select>
          </v-col>
          <v-col cols="1">
            <v-btn icon :disabled="processed || loading" @click="removeLocalization(index)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </td>
  </tr>
</template>

<script>
import HTTP from '@/api/ant';
import Rules from 'bsm-validation-rules';
import TreeSearch from '@/components/mv/TreeSearch';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import ClassifyRowMixin from '@/components/cls/ClassifyRowMixin';
import OwlDatePicker from '@/components/OwlDatePicker';
import SaveBtn from '@/components/cls/SaveBtn.vue';
import DoneChip from '@/components/cls/DoneChip.vue';

const rules = {
  pattern: [Rules.presence(), Rules.format(/^[\w\-*]+(\.[\w\-*]+)+$/)],
  property: [Rules.presence()],
  treeId: [Rules.presence()],
};

export default {
  components: { DoneChip, SaveBtn, OwlDatePicker, TreeSearch },
  mixins: [ClassifyRowMixin],
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      model: {
        pattern: '',
        tree_id: null,
        property: null,
        effective_start_date: null,
      },
      rules: {
        pattern: [],
        property: [],
        treeId: [],
      },
      ottmProperties: [],
      localizations: [],
      countries: [
        { text: 'default', value: '*' },
        { text: 'us', value: 'us' },
      ],
      payloadArray: [],
      formattedPayload: null,
    };
  },
  computed: {
    filteredCountries() {
      return this.countries.filter(country => country.value !== '*');
    },
    getCountry() {
      return rowCountry => rowCountry || 'us';
    },
  },
  created() {
    this.enumFetch(HTTP, {
      ottmProperties: '/ottm/rules/enum/properties',
    });
  },
  methods: {
    save() {
      this.rules = rules;
      this.createPayloadArray();
      this.submitClassify(`/ottm/multiplerules`);
    },
    enumFetch,
    addLocalization() {
      this.localizations.push({
        tree_id: null,
        country: '',
      });
    },

    removeLocalization(index) {
      this.localizations.splice(index, 1);
    },
    createPayloadArray() {
      const baseObject = {
        pattern: this.model.pattern,
        property: this.model.property,
        effective_start_date: this.model.effective_start_date,
      };

      this.payloadArray = [
        {
          ...baseObject,
          tree_id: this.model.tree_id,
          localization: '*',
        },
      ];

      this.localizations.forEach(localization => {
        this.payloadArray.push({
          ...baseObject,
          tree_id: localization.tree_id,
          localization: localization.country,
        });
      });
      this.formattedPayload = { rules: this.payloadArray };
      return this.payloadArray;
    },
  },
};
</script>
