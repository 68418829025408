<template>
  <v-dialog :value="value" persistent max-width="600px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      data-cy="brand-channel-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <template #title-extend>
        <v-spacer />
        <v-btn v-if="channel && brand" text outlined @click="useBrandValues">
          Use Brand Values
        </v-btn>
      </template>
      <v-row>
        <v-col cols="12">
          <v-text-field
            ref="name"
            v-model="item.name"
            data-cy="name"
            :rules="rules.name"
            label="Name"
            counter="500"
          />
        </v-col>
        <v-col v-if="editing || cls" cols="12">
          <v-row>
            <v-col cols="12" md="8">
              <tree-search
                v-if="item[`${parentEntityType}_id`] || (cls && value)"
                v-model="item[`${parentEntityType}_id`]"
                :mode="parentEntityType"
                :rules="rules.tree"
                :country-code="editingCountryCode"
              />
            </v-col>
            <v-col cols="12" md="4">
              <div class="d-flex align-center">
                <v-icon class="mr-5">mdi-filter-variant</v-icon>
                <v-select
                  v-model="editingCountryCode"
                  :items="countries"
                  label="Country"
                  :disabled="editing || processed || loading"
                  @change="onCountryChange"
                ></v-select>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <ad-supported v-model="item.ad_supported" />
        </v-col>
        <v-col cols="12" sm="6">
          <status-selector v-model="item.status" />
        </v-col>
        <v-col cols="12">
          <subcategory-selector
            v-if="value"
            v-model="item.sub_category_id"
            :editing="item.sub_category"
          />
        </v-col>
        <v-col :sm="editing ? 6 : 12" cols="12">
          <owl-date-picker v-model="item.start_date" :clearable="!editing" label="Start Date" />
        </v-col>
        <v-col v-if="editing" cols="12" sm="6">
          <owl-date-picker v-model="item.end_date" :start-date="item.start_date" label="End Date" />
        </v-col>
        <v-col v-if="editing" cols="12">
          <v-text-field v-model="item.reason" label="Reason" />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/owl';
import AdSupported from '@/components/mv/AdSupported';
import StatusSelector from '@/components/mv/StatusSelector';
import Rules from 'bsm-validation-rules';
import SubcategorySelector from '@/components/mv/SubcategorySelector';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import TreeSearch from '@/components/mv/TreeSearch';
import OwlDatePicker from '@/components/OwlDatePicker';

const rules = {
  name: [Rules.presence(), Rules.length({ max: 500 })],
  tree: [Rules.presence()],
};

export default {
  components: { OwlDatePicker, TreeSearch, SubcategorySelector, StatusSelector, AdSupported },
  mixins: [FormMixin],
  props: {
    channel: {
      type: Boolean,
      default: false,
    },
    postEndpoint: {
      type: String,
      default: null,
    },
    cls: {
      type: Boolean,
      default: false,
    },
    brand: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    item: {},
    rules,
    countries: [
      { text: 'default', value: '*' },
      { text: 'us', value: 'us' },
    ],
    editingCountryCode: '*',
  }),
  computed: {
    parentEntityType() {
      return this.channel ? 'brand' : 'parent';
    },
  },
  watch: {
    editing: {
      immediate: true,
      handler(isEditing) {
        if (isEditing && this.item.country_codes && this.item.country_codes.length > 0) {
          this.editingCountryCode = this.item.country_codes[0];
        }
      },
    },
  },

  methods: {
    formSave() {
      let path;
      if (this.item.country_codes) {
        this.item.country_code = this.item.country_codes[0];
      }

      if (this.editing != null) {
        path = this.channel ? `/channels` : `/brands`;
      } else {
        path = this.cls ? `/brand/${this.item.brand_id}/channel` : this.postEndpoint;
      }

      return this.submitForm(HTTP, path, {
        success: ({ data }) => {
          this.$flash('success', `${data.name} ${this.formContext} ${this.formSuccessMsg}`);
        },
      });
    },
    formReady() {
      this.formContext = this.channel ? 'Channel' : 'Brand';
      if (this.editing && this.item.country_codes && this.item.country_codes.length > 0) {
        this.editingCountryCode = this.item.country_codes[0];
      }
    },
    useBrandValues() {
      this.item = { ...this.brand, name: undefined, id: undefined };
    },
    onCountryChange(newCountry) {
      this.editingCountryCode = newCountry;
    },
  },
};
</script>
