<template>
  <v-dialog :value="value" persistent max-width="600px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      data-cy="parent-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row>
        <v-col cols="12">
          <v-text-field
            ref="name"
            v-model="item.name"
            data-cy="parent-name"
            :rules="rules.name"
            label="Name"
            counter="500"
          />
        </v-col>
        <v-col cols="12">
          <owl-date-picker v-model="item.start_date" :clearable="!editing" label="Start Date" />
        </v-col>
        <v-col v-if="!editing" cols="12" class="pl-4">
          <v-select
            v-model="localization"
            :items="filter_localization"
            label="Select a localization"
            outlined
            :default="'default'"
            height="40"
          />
        </v-col>
        <v-col v-if="editing" cols="12">
          <status-selector v-model="item.status" />
        </v-col>
        <v-col v-if="editing" cols="12">
          <v-text-field v-model="item.reason" class="parent_reason" label="Reason" />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/owl';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import StatusSelector from '@/components/mv/StatusSelector';
import Rules from 'bsm-validation-rules';
import OwlDatePicker from '@/components/OwlDatePicker';

const rules = {
  name: [Rules.presence(), Rules.length({ max: 500 })],
};

export default {
  components: { OwlDatePicker, StatusSelector },
  mixins: [FormMixin],
  data: () => ({
    formContext: 'Parent',
    localization: '*',
    filter_localization: [
      { text: 'default', value: '*' },
      { text: 'us', value: 'us' },
    ],
    rules,
  }),
  methods: {
    formSave() {
      let path = '',
        data;
      if (this.editing != null) {
        path = '/parents';
        this.item.country_code = this.item.country_codes[0];
        data = this.item;
      } else {
        this.item.country_codes = [this.localization];
        path = `/proposals/tree`;
        data = { parent: this.item };
      }
      return this.submitForm(HTTP, path, {
        data,
        success: ({ data }) => {
          this.$flash('success', `${data.name} parent ${this.formSuccessMsg}`);
        },
      });
    },
  },
};
</script>
