import HTTP from '@/api/ant';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    processed: false,
    loading: false,
  }),
  methods: {
    onTime(v) {
      return new Date() < new Date(v);
    },
    submitClassify(path, opts) {
      const options = opts || {};
      this.$nextTick(() => {
        if (!this.$refs.form.validate()) {
          return;
        }
        this.loading = true;
        return HTTP.post(path, this.formattedPayload, { niSkipFlash: true })
          .then(res => {
            this.processed = true;
            this.$flash('success', 'Create rule proposal successfully created');
            if (options.success) options.success.bind(this)(res);
            return res;
          })
          .catch(err => {
            if (err.response?.status === 400 && err.response?.data?.errors != null) {
              const key = Object.keys(err.response.data.errors)[0];
              this.$flash('error', `${key} ${err.response.data.errors[key]}`);
            }
            if (options.failure) options.failure.bind(this)(err);
            return Promise.reject(err);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
};
