<template>
  <tr ref="cls" :class="{ 'blue-grey darken-2': processed }">
    <td colspan="7">
      <v-form ref="form">
        <v-row align="center" dense class="my-0">
          <v-col cols="1">
            <v-btn
              icon
              small
              class="v-data-table__expand-icon ml-n3 ml-xl-0"
              :class="{ 'v-data-table__expand-icon--active': isExpanded }"
              @click="$emit('expand', !isExpanded)"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn :href="item.media_url" target="_blank" icon small>
              <v-icon>mdi-video</v-icon>
            </v-btn>
            <v-btn :href="item.site_urls[0]" target="_blank" icon small>
              <v-icon>mdi-application</v-icon>
            </v-btn>
          </v-col>
          <v-col v-placeholder cols="3" class="text-truncate pt-3">
            <span v-if="item.site_urls[0]">{{ item.site_urls[0] | truncate(100) }}</span>
            <v-row justify="space-between" no-gutters class="mt-2">
              <nv-show cols="auto" header="Last seen on">
                <span>{{ item.last_seen_on | date }}</span>
              </nv-show>
              <nv-show cols="auto" header="Countries">
                <span v-for="(c, i) in item.countries" :key="i">
                  {{ c | uppercase }}
                  <span v-if="i < item.countries.length - 1" class="mr-1">,</span>
                </span>
              </nv-show>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-textarea
              ref="pattern"
              v-model="model.pattern"
              :rules="rules.pattern"
              :disabled="processed || loading"
              rows="1"
              auto-grow
              label="Pattern"
              hide-details="auto"
              solo
              flat
            />
          </v-col>
          <v-col :cols="model.effective_start_date ? 2 : 2">
            <hierarchy-discovery
              v-model="model.tree_id"
              :rules="rules.treeId"
              :disabled="processed || loading"
              :country="'*'"
            />
          </v-col>
          <v-col :cols="model.effective_start_date ? 3 : 2">
            <v-row dense align="center" class="flex-nowrap">
              <v-col>
                <v-select
                  ref="property"
                  v-model="model.property"
                  :items="videoProperties"
                  :rules="rules.property"
                  :disabled="processed || loading"
                  placeholder="Property"
                  solo
                  flat
                  hide-details="auto"
                />
              </v-col>
              <nv-show
                :header="model.effective_start_date ? 'Start Date' : ''"
                :class="{ 'mt-2': !model.effective_start_date }"
                cols="auto"
                no-placeholder
                class="pl-lg-2"
              >
                <plus-icon-button :disabled="isAddDisabled" @add="addLocalization" />
                <owl-date-picker
                  v-model="model.effective_start_date"
                  :disabled="processed || loading"
                  label="Start Date"
                  icon
                  clearable
                />
                <span v-if="model.effective_start_date">
                  {{ model.effective_start_date }}
                </span>
              </nv-show>
              <v-col v-if="lockedBy === $auth.user.email" cols="auto">
                <template v-if="onTime(lockExpiresAt)">
                  <save-btn
                    v-if="!processed"
                    :loading="loading"
                    save-text="Test"
                    class="ml-lg-4"
                    @click="testPatternDialog.show = true"
                  />
                  <done-chip v-else />
                </template>
                <expire-chip v-else />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          v-for="(row, index) in localizations.slice(0, Math.max(1, countries.length - 1))"
          :key="index"
          align="center"
          class="my-0 "
        >
          <v-col cols="4" lg="5">
            <hierarchy-discovery
              v-model="row.tree_id"
              :rules="rules.treeId"
              :disabled="processed || loading"
              :country="getCountry(row.country)"
            />
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="row.country"
              :items="filteredCountries"
              label="Country"
              :disabled="processed || loading"
            ></v-select>
          </v-col>
          <v-col cols="1">
            <owl-date-picker
              v-model="model.effective_start_date"
              :disabled="processed || loading"
              label="Start Date"
              icon
              clearable
            />
          </v-col>

          <v-col cols="1">
            <v-btn icon :disabled="processed || loading" @click="removeLocalization(index)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <test-pattern-dialog
        v-model="testPatternDialog.show"
        :domain="item.domain"
        :pattern="model.pattern"
        product="video"
        @saved="save"
      />
    </td>
  </tr>
</template>

<script>
import HTTP from '@/api/ant';
import Rules from 'bsm-validation-rules';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import ClassifyRowMixin from '@/components/cls/ClassifyRowMixin';
import OwlDatePicker from '@/components/OwlDatePicker';
import HierarchyDiscovery from '@/components/cls/video/HierarchyDiscovery';
import TestPatternDialog from '@/components/cls/TestPatternDialog';
import SaveBtn from '@/components/cls/SaveBtn.vue';
import DoneChip from '@/components/cls/DoneChip.vue';
import ExpireChip from '@/components/cls/ExpireChip.vue';
import PlusIconButton from '@/components/PlusIconButton.vue';

const rules = {
  pattern: [
    Rules.presence(),
    Rules.format(/^[^\r\n]*$/, 'should not include a new line character'),
  ],
  property: [Rules.presence()],
  treeId: [Rules.presence()],
};

export default {
  components: {
    ExpireChip,
    DoneChip,
    SaveBtn,
    TestPatternDialog,
    HierarchyDiscovery,
    OwlDatePicker,
    PlusIconButton,
  },
  mixins: [ClassifyRowMixin],
  props: {
    recentItems: {
      type: Array,
      default: () => [],
    },
    isExpanded: {
      type: Boolean,
      required: true,
    },
    lockedBy: {
      type: String,
      default: '',
    },
    lockExpiresAt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      model: {
        pattern: this.item.media_url.replace(/^\w*:\/\//, ''),
        tree_id: null,
        property: 'valid',
        effective_start_date: null,
      },
      rules: {
        pattern: [],
        property: [],
        treeId: [],
      },
      videoProperties: [],
      testPatternDialog: { show: false },
      localizations: [],
      countries: [
        { text: 'default', value: '*' },
        { text: 'us', value: 'us' },
      ],
      payloadArray: [],
      formattedPayload: null,
    };
  },
  computed: {
    filteredCountries() {
      return this.countries.filter(country => country.value !== '*');
    },
    getCountry() {
      return rowCountry => rowCountry || 'us';
    },
  },
  watch: {
    'item.media_url': function(v) {
      if (!this.processed) {
        this.model.pattern = v.replace(/^\w*:\/\//, '');
      }
    },
  },
  created() {
    this.enumFetch(HTTP, {
      videoProperties: '/video/rules/enum/properties',
    });
  },
  methods: {
    save() {
      this.createPayloadArray();
      this.rules = rules;
      this.submitClassify(`/video/discoveries/${this.item.id}/multiplerules`, {
        success: () => {
          this.$emit('saved');
        },
      });
    },
    enumFetch,
    addLocalization() {
      this.localizations.push({
        tree_id: null,
        country: '',
      });
    },

    removeLocalization(index) {
      this.localizations.splice(index, 1);
    },
    createPayloadArray() {
      const baseObject = {
        pattern: this.model.pattern,
        property: this.model.property,
        effective_start_date: this.model.effective_start_date,
      };

      this.payloadArray = [
        {
          ...baseObject,
          tree_id: this.model.tree_id,
          localization: '*',
        },
      ];

      this.localizations.forEach(localization => {
        this.payloadArray.push({
          ...baseObject,
          tree_id: localization.tree_id,
          localization: localization.country,
        });
      });
      this.formattedPayload = { rules: this.payloadArray };
      return this.payloadArray;
    },
  },
};
</script>
