<template>
  <v-container
    :class="{ 'is-blank': !items.length }"
    class="nv-page owl-search"
    data-cy="mv-search"
  >
    <v-row no-gutters>
      <v-toolbar class="nv-page-toolbar align-center" flat extension-height="158">
        <v-toolbar-title class="mr-4">Market View</v-toolbar-title>
        <nv-btn
          v-if="$auth.hasPermissions('owl.marketview')"
          type="add"
          data-cy="add-parent"
          @click="form.show = true"
        />
        <template #extension>
          <v-row dense align-content="space-between" class="fill-height">
            <find-by-id />
            <v-col cols="12" class="d-flex align-center pb-3">
              <nv-table-search v-model="search" data-cy="search-box" />
              <v-icon class="mr-2 ml-6">mdi-filter-variant</v-icon>
              <v-btn-toggle v-model="filters.localizations" class="ml-2">
                <v-btn
                  v-for="item in localizations"
                  :key="item.text"
                  data-cy="hierarchy-market"
                  :value="item.value"
                  text
                  small
                >
                  <span class="hidden-sm-and-down">{{ item.text }}</span>
                </v-btn>
              </v-btn-toggle>
              <v-btn-toggle v-model="filters.type" multiple class="ml-2">
                <v-btn
                  v-for="item in types"
                  :key="item"
                  data-cy="hierarchy-level"
                  :value="item"
                  text
                  small
                >
                  <span class="hidden-sm-and-down">{{ item }}</span>
                  <v-icon class="hidden-md-and-up">{{ `mdi-alpha-${item[0]}` }}</v-icon>
                </v-btn>
              </v-btn-toggle>
              <v-btn-toggle v-model="filters.status" multiple mandatory class="ml-4">
                <v-btn v-for="item in statuses" :key="item.text" :value="item.value" text small>
                  {{ item.text }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </template>
      </v-toolbar>
    </v-row>
    <v-progress-linear :active="loading" indeterminate />
    <parent-form ref="parentForm" v-model="form.show" @saved="redirect" />

    <v-card v-if="items.length" class="mt-1" data-cy="mv-search-list">
      <v-list two-line>
        <v-list-item v-for="(item, i) in items" :key="i">
          <search-list-avatar :type="item.type" />
          <search-list-content :item="item" link show-localizations />
        </v-list-item>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
import HTTP from '@/api/owl-search';
import ParentForm from '@/components/mv/parents/ParentForm';
import { SearchMixin, FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import owlSearchMixin from '@/helpers/owlSearchMixin';
import SearchListContent from '@/components/mv/SearchListContent';
import SearchListAvatar from '@/components/mv/SearchListAvatar';
import FindById from '@/components/mv/FindById';

export default {
  components: { FindById, SearchListAvatar, SearchListContent, ParentForm },
  mixins: [SearchMixin, FiltersMixin, owlSearchMixin],
  data: () => ({
    form: {
      show: false,
    },
    filters: {
      type: [],
      status: [],
      localizations: '*',
    },
    types: ['parent', 'brand', 'channel'],
    localizations: [
      { text: 'default', value: '*' },
      { text: 'us', value: 'us' },
    ],
    statuses: [
      { text: 'Active', value: 'active,legacy' },
      { text: 'Inactive', value: 'inactive' },
      { text: 'Archived', value: 'archived' },
    ],
  }),
  methods: {
    redirect(res) {
      this.$router.push({
        name: 'owl.mv.proposals.tree.show',
        params: { treeId: res.current.data.id },
      });
    },
    fetchData() {
      this.loading = true;
      const type = this.filters.type.join(',');
      const params = {
        q: this.search,
        type,
        status: this.filters.status.join(','),
        country_code: this.filters.localizations,
      };
      HTTP.get('/tree/search', { params })
        .then(({ data }) => {
          if (params.q === this.search) {
            this.items = data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.owl-search {
  transition: margin-top 0.3s ease;
  margin-top: 0;
}
@media (min-width: 1264px) {
  .is-blank {
    margin-top: 150px;
  }
}
</style>
