<template>
  <v-btn icon :disabled="disabled" @click="$emit('add')">
    <v-icon>mdi-plus</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'PlusIconButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
