<template>
  <v-navigation-drawer permanent app fixed right width="300">
    <template #prepend>
      <v-list-item v-if="treeId" dense>
        <v-list-item-content>
          <nv-table-search v-model="search" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="treeId" dense>
        <v-list-item-content>
          <v-select
            v-model="status"
            :items="statuses"
            multiple
            chips
            deletable-chips
            solo
            hide-details
            flat
            small-chips
            label="Status"
          />
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-treeview v-if="items.length > 0" :items="items" :active="active" open-all dense hoverable>
      <template #label="{ item }">
        <router-link
          v-if="item.display_id !== treeId"
          :to="showLink(item)"
          class="text-decoration-none"
        >
          {{ item.name }}
        </router-link>
        <span v-else>{{ item.name }}</span>
      </template>
      <template #prepend="{ item }">
        <router-link
          v-if="item.has_children && !item.children"
          :to="showLink(item)"
          class="text-decoration-none"
        >
          <v-icon dense class="ml-n6">mdi-menu-right</v-icon>
        </router-link>
      </template>
    </v-treeview>
  </v-navigation-drawer>
</template>

<script>
import HTTP from '@/api/owl-search';
import OWL from '@/api/owl';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import { getCountryByValue } from '@/helpers/filters';

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    treeId: Number,
    countryCode: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    search: '',
    items: [],
    open: [],
    active: [],
    status: ['active'],
    statuses: [],
  }),
  watch: {
    search() {
      this.fetchData();
    },
    status() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
    this.enumFetch(OWL, {
      statuses: '/enum/statuses',
    });
  },
  methods: {
    fetchData() {
      if (this.treeId == null) {
        return;
      }
      HTTP.get('/tree/excerpt', {
        params: {
          id: this.treeId,
          status: this.status.join(','),
          q: this.search,
          limit: 1000,
          country_code: getCountryByValue(this.countryCode),
        },
      }).then(({ data }) => {
        const sortFn = (a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        };
        if (data.children) {
          data.children.sort(sortFn);
          data.children.forEach(el => {
            if (el.children) {
              el.children.sort(sortFn);
            }
          });
        }
        this.items = [data];
        this.active = [this.treeId];
      });
    },
    showLink(item) {
      const param = item.type === 'parent' ? 'parentId' : 'childId';
      return {
        name: `owl.${item.type}s.show`,
        params: {
          [param]: item.display_id,
        },
        query: {
          countryCode: getCountryByValue(this.countryCode),
        },
      };
    },
    enumFetch,
  },
};
</script>

<style>
.theme--dark .v-navigation-drawer__prepend .v-text-field--solo .v-input__control .v-input__slot {
  background-color: #2b333f !important;
}
.v-treeview--dense .v-icon.v-treeview-node__toggle {
  font-size: 20px;
}
.v-treeview--dense .v-treeview-node__prepend {
  min-width: 0;
  margin-right: 0;
}
</style>
