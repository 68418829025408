<template>
  <div>
    <div v-if="label" class="text-caption font-weight-medium">{{ label }}</div>
    <v-autocomplete
      ref="tree-search"
      v-model="selected"
      :items="items"
      :loading="loading"
      :search-input.sync="search"
      :prepend-inner-icon="selected ? `mdi-alpha-${selected.type[0]}-box-outline` : 'mdi-magnify'"
      :hide-details="inline && 'auto'"
      :clearable="inline"
      :solo="inline"
      :flat="inline"
      :rules="rules"
      :disabled="disabled"
      :menu-props="{ maxHeight: 325 }"
      item-text="name"
      item-value="display_id"
      return-object
      placeholder="Search"
      hide-no-data
      data-cy="tree-search"
      @change="change"
    >
      <template #item="{ item }">
        <search-list-avatar :type="item.type" />
        <search-list-content :item="item" />
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import HTTP from '@/api/owl-search';
import { debounce } from 'lodash';
import SearchListContent from '@/components/mv/SearchListContent';
import SearchListAvatar from '@/components/mv/SearchListAvatar';
import { getCountryByValue } from '@/helpers/filters';

const modes = ['classification', 'parent', 'brand'];

function updateSearch(val) {
  if (val) {
    if (!this.selected || val.toLowerCase() !== this.selected.name.toLowerCase()) {
      this.fetchData(val);
    }
  }
}

export default {
  components: { SearchListAvatar, SearchListContent },
  props: {
    value: {
      type: Number,
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: null,
      validator: v => modes.indexOf(v) !== -1,
    },
    countryCode: {
      type: String,
      default: '*',
      required: false,
    },
  },
  data() {
    return {
      items: [],
      search: '',
      loading: false,
      selected: null,
      filters: [],
    };
  },
  watch: {
    search: debounce(updateSearch, 500),
    countryCode: {
      handler() {
        this.clearSelection();
      },
      immediate: true,
    },
  },
  async created() {
    if (this.value) {
      this.selected = await this.findTree(this.value);
    }
    if (this.mode === 'classification') {
      this.filters = ['channel', 'brand'];
    } else if (this.mode) {
      this.filters = [this.mode];
    }
  },
  methods: {
    findTree(id) {
      this.loading = true;
      return HTTP.get('/tree/find', {
        params: { id, country_code: getCountryByValue(this.countryCode) },
      })
        .then(({ data }) => {
          this.items = data;
          return this.items[0];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchData(search) {
      this.loading = true;
      const type = this.filters.join(',');
      const params = {
        q: search,
        country_code: this.countryCode,
        type,
      };
      if (this.mode === 'classification') {
        params.purpose = 'classification';
      }
      HTTP.get('/tree/search', { params })
        .then(({ data }) => {
          if (params.q === this.search) {
            this.items = data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async change(val) {
      let id = undefined;
      if (val) {
        if (this.mode === 'brand' || this.mode === 'parent') {
          id = val.id;
        } else {
          id = val.display_id;
        }
      }
      this.$emit('input', id);
    },
    clearSelection() {
      this.items = [];
      this.selected = null;
    },
  },
};
</script>
