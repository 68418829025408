export function formatFilters(filters, countries, primaryKey = 'country_code') {
  const formatted = {};
  Object.keys(filters).forEach(key => {
    if (key === primaryKey) {
      const country = countries.find(c => c.code === filters[key]);
      if (country) {
        formatted['country'] = country.name;
      }
    } else {
      formatted[key] = filters[key];
    }
  });
  return formatted;
}

export const _ = null;

export function getCountryByValue(country_code) {
  if (country_code == 'default') return '*';
  return country_code == 'Default' ? '*' : country_code;
}
export function resolveCountryCode(countryCode) {
  return countryCode === '*' ? 'Default' : countryCode;
}
