<template>
  <nv-tab-page>
    <template #toolbar>
      <v-menu offset-y>
        <template #activator="{ on }">
          <v-slide-x-transition>
            <v-btn
              v-if="classifiable && ruleType === 'domain'"
              color="secondary"
              class="ml-n4"
              data-cy="add-domain-btn"
              v-on="on"
            >
              <v-icon class="mr-1">mdi-chevron-down</v-icon>
              Add
            </v-btn>
          </v-slide-x-transition>
        </template>
        <v-list data-cy="add-menu">
          <v-list-item data-cy="add-single" @click="domainsForm.show = true">
            <v-list-item-title>
              Single
            </v-list-item-title>
          </v-list-item>
          <v-list-item data-cy="add-bulk" @click="bulkDomainsForm.show = true">
            <v-list-item-title>
              Bulk
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-if="$auth.hasPermissions('ant.discovery')"
        :disabled="!selected.length"
        color="accent"
        data-cy="rules-transfer-btn"
        @click="openTransferForm()"
      >
        <v-icon class="mr-1">mdi-transfer</v-icon>
        <span>Transfer</span>
      </v-btn>
      <v-btn
        v-if="$auth.hasPermissions('ant.discovery')"
        :disabled="!selected.length"
        color="secondary"
        data-cy="rules-status-btn"
        @click="openStatusForm()"
      >
        <v-icon class="mr-1">mdi-pencil</v-icon>
        <span>Edit Status</span>
      </v-btn>
      <v-spacer />
      <v-btn-toggle v-model="ruleType" mandatory data-cy="type-selector">
        <v-btn v-for="rule in ruleTypes" :key="rule.value" :value="rule.value" text small>
          <span class="hidden-md-and-down">{{ rule.text }}</span>
          <v-icon class="hidden-lg-and-up">
            {{ `mdi-alpha-${rule.text[0].toLowerCase()}` }}
          </v-icon>
        </v-btn>
      </v-btn-toggle>
      <nv-table-search v-model="search" sync class="ml-4 mr-n4" />
    </template>

    <template #filters>
      <nv-table-filters ref="applied-filters" v-model="tableFilters" no-clear>
        <filter-sheet ref="filter-sheet" :statuses="typeStatuses" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      v-model="selected"
      :headers="headers()"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      show-select
    >
      <template #item.match_type="{ item }">
        {{ item.match_type | humanize }}
      </template>

      <template #item.ua="{ item }">
        <div class="d-flex justify-space-between">
          <v-btn v-if="ruleType === 'ios'" icon @click="openUAForm(item.id)">
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>
        </div>
      </template>

      <template #item.pattern="{ item }">
        <div class="d-flex justify-space-between align-center">
          <router-link :to="{ name: `owl.${ruleType}.rules.show`, params: { ruleId: item.id } }">
            {{ item.pattern }}
          </router-link>
          <v-btn
            v-if="ruleType === 'domain'"
            :href="'https://' + item.pattern"
            icon
            target="_blank"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </div>
      </template>

      <template #item.updated="{ item }">
        <updated-details :item="item" />
      </template>

      <template #item.status="{ item }">
        {{ item.status | humanize }}
      </template>
      <template #item.country="{ item }">
        {{ resolveCountryCode(item.localization) }}
      </template>
    </v-data-table>
    <domain-form v-model="domainsForm.show" :tree-id="id" />
    <bulk-domain-form v-model="bulkDomainsForm.show" :tree-id="id" />
    <transfer-patterns-form
      v-model="transferForm.show"
      :kind="ruleType"
      :selected-ids="selectedIds"
      :child-id="id"
      @saved="saved"
    />
    <rules-status-form
      v-model="statusForm.show"
      :rule-type="ruleType"
      :editing="statusForm.edit"
      :statuses="availableStatuses"
      @saved="saved"
    />
    <ua-dialog v-model="uaDialog.show" :match-id="uaDialog.matchId" />
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/ant';
import { TableMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import FilterSheet from '@/components/mv/PatternsFilterSheet';
import DomainForm from '@/components/mv/DomainForm';
import TransferPatternsForm from '@/components/mv/TransferPatternsForm';
import UaDialog from '@/components/cls/ios/UaDialog';
import RulesStatusForm from '@/components/cls/RulesStatusForm';
import BulkDomainForm from '@/components/mv/BulkDomainForm.vue';
import UpdatedDetails from '@/components/cls/UpdatedDetails.vue';
import { resolveCountryCode } from '@/helpers/filters';

const defaultHeaders = [
  { text: 'ID', sortable: true, value: 'id' },
  { text: 'Pattern', sortable: false, value: 'pattern' },
  { text: 'Status', sortable: false, value: 'status' },
  { text: 'Updated', sortable: true, value: 'updated' },
  { text: 'Market', sortable: false, value: 'country' },
];
const iosHeaders = [
  { text: 'ID', sortable: true, value: 'id' },
  { text: 'Pattern', sortable: false, value: 'pattern' },
  { text: 'Match Type', sortable: false, value: 'match_type' },
  { text: 'User Agents', sortable: false, value: 'ua' },
  { text: 'Domain', sortable: false, value: 'domain' },
  { text: 'Status', sortable: false, value: 'status' },
  { text: 'Updated', sortable: true, value: 'updated' },
  { text: 'Market', sortable: false, value: 'country' },
];
const types = {
  tree: [
    { text: 'Domain', value: 'domain' },
    { text: 'Connected Devices', value: 'ottm' },
    { text: 'Video', value: 'video' },
  ],
  app_dictionary: [
    { text: 'Android', value: 'android' },
    { text: 'IOS', value: 'ios' },
  ],
};
const kinds = ['tree', 'app_dictionary'];

export default {
  components: {
    UpdatedDetails,
    BulkDomainForm,
    UaDialog,
    RulesStatusForm,
    TransferPatternsForm,
    DomainForm,
    FilterSheet,
  },
  mixins: [TableMixin],
  props: {
    id: { type: Number, required: true },
    kind: {
      type: String,
      required: true,
      validator: v => kinds.indexOf(v) !== -1,
    },
    classifiable: {
      type: Boolean,
      default: false,
    },
    countryCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      settings: {
        [`${this.kind}_id`]: this.id,
        localization: this.countryCode === 'Default' ? '*' : this.countryCode,
      },
      ruleTypes: types[this.kind],
      ruleType: this.kind === 'tree' ? 'domain' : 'android',
      tableParam: 'patterns.dt',
      domainsForm: { show: false },
      bulkDomainsForm: { show: false },
      selected: [],
      transferForm: {
        show: false,
        selectedIds: [],
      },
      uaDialog: {
        show: false,
        matchId: 0,
      },
      statusForm: {
        show: false,
        edit: null,
      },
      domainStatuses: [],
      ottmStatuses: [],
      videoStatuses: [],
      androidStatuses: [],
      iosStatuses: [],
    };
  },
  computed: {
    selectedIds() {
      return this.selected.map(el => el.id);
    },
    availableStatuses() {
      return this.typeStatuses.filter(s => s.value !== this.tableFilters.status);
    },
    typeStatuses() {
      return this[`${this.ruleType}Statuses`];
    },
  },
  watch: {
    ruleType() {
      this.selected = [];
      this.items = [];
      if (this.search) {
        this.search = '';
      } else {
        this.tableFetch();
      }
    },
  },
  created() {
    this.enumFetch(HTTP, {
      domainStatuses: '/domain/rules/enum/statuses',
      ottmStatuses: '/ottm/rules/enum/statuses',
      videoStatuses: '/video/rules/enum/statuses',
      androidStatuses: '/android/rules/enum/statuses',
      iosStatuses: '/ios/rules/enum/statuses',
    });
  },
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, `/${this.ruleType}/rules`);
    },
    openTransferForm() {
      this.transferForm.show = true;
    },
    saved() {
      this.selected = [];
      this.tableFetch();
    },
    openStatusForm() {
      this.statusForm.edit = {
        ids: this.selectedIds,
        status: null,
      };
      this.statusForm.show = true;
    },
    openUAForm(id) {
      this.uaDialog.matchId = id;
      this.uaDialog.show = true;
    },
    headers() {
      return this.ruleType === 'ios' ? iosHeaders : defaultHeaders;
    },
    enumFetch,
    resolveCountryCode,
  },
};
</script>
