<template>
  <tr :class="{ 'blue-grey darken-2': processed, 'elevation-3': editing }">
    <td colspan="4">
      <v-form ref="form">
        <v-row align="center" class="my-0">
          <v-col :cols="type === 'ua' ? 3 : 6" class="d-flex align-center pr-0">
            <slot name="select" :selectable="!processed && !loading && !editing"></slot>
            <span v-if="!editing" class="text-break">{{ model.pattern }}</span>
            <v-textarea
              v-else
              v-model="model.pattern"
              :rules="rules.pattern"
              :disabled="processed || loading"
              append-icon="mdi-restore"
              solo
              flat
              hide-details="auto"
              rows="1"
              auto-grow
              class="text-subtitle-2"
              data-cy="pattern-input"
              @click:append="resetPattern"
            />
            <v-btn
              v-if="type === 'ua'"
              :disabled="isDisabled"
              icon
              class="ml-auto"
              @click="editPattern"
            >
              <v-icon>{{ editing ? 'mdi-close' : 'mdi-pencil' }}</v-icon>
            </v-btn>
          </v-col>
          <v-col
            v-if="type === 'ua'"
            v-placeholder
            cols="2"
            class="d-flex justify-space-between align-center"
          >
            <span>{{ domain }}</span>
            <v-btn v-if="domain" icon @click="domain = ''">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn v-else-if="item.domain" icon @click="domain = item.domain">
              <v-icon>mdi-restore</v-icon>
            </v-btn>
          </v-col>
          <v-col :cols="type === 'ua' ? 3 : 4" :lg="type === 'ua' ? 2 : 4">
            <app-dict-search
              v-model="model.app_dictionary_id"
              :rules="rules.appId"
              :disabled="isDisabled"
              inline
            />
          </v-col>
          <v-col v-if="type === 'ua'" v-placeholder class="text-truncate" cols="1" lg="2">
            {{ item.app_name }}
          </v-col>
          <v-col v-if="type === 'ua'" v-placeholder class="text-truncate" cols="1">
            {{ item.countries.join(', ') | uppercase }}
          </v-col>
          <nv-show :header="model.effective_start_date ? 'Start Date' : ''" cols="1" no-placeholder>
            <owl-date-picker
              v-model="model.effective_start_date"
              :disabled="isDisabled"
              label="Start Date"
              icon
              clearable
            />
            <span v-if="model.effective_start_date" class="mr-1">
              {{ model.effective_start_date }}
            </span>
          </nv-show>
          <v-col
            v-if="item.locked_by === $auth.user.email"
            cols="1"
            class="d-flex justify-end align-center"
          >
            <template v-if="onTime(item.lock_expires_at)">
              <v-btn
                v-if="!processed"
                ref="save-btn"
                :loading="loading"
                :disabled="isDisabled"
                color="secondary"
                small
                class="ml-4"
                @click="onSave"
              >
                {{ isWildCardPattern() ? 'Test' : 'Save' }}
                <template #loader>
                  <nv-circular-loader />
                </template>
              </v-btn>
              <done-chip v-else />
            </template>
            <expire-chip v-else />
          </v-col>
        </v-row>
        <v-row v-if="editing">
          <nv-show
            :cols="isAutoFormat(item.user_agent) ? 4 : 6"
            :sm="isAutoFormat(item.user_agent) ? 4 : 6"
            header="User Agent"
          >
            <span class="text-break">{{ item.user_agent }}</span>
          </nv-show>
          <v-col v-if="isAutoFormat(item.user_agent)" cols="2">
            <v-btn text outlined class="mt-1" @click="autoCreate(item.user_agent)">
              Auto Pattern
            </v-btn>
          </v-col>
          <wildcards-table />
        </v-row>
      </v-form>
      <test-pattern-dialog
        v-if="isWildCardPattern()"
        v-model="testPatternDialog.show"
        :domain="domain"
        :pattern="model.pattern"
        product="ios/ua"
        @saved="save"
      />
    </td>
  </tr>
</template>

<script>
import AppDictSearch from '@/components/mv/AppDictSearch';
import ClassifyRowMixin from '@/components/cls/ClassifyRowMixin';
import OwlDatePicker from '@/components/OwlDatePicker';
import Rules from 'bsm-validation-rules';
import TestPatternDialog from '@/components/cls/TestPatternDialog';
import WildcardsTable from '@/components/cls/ios/WildcardsTable';
import DoneChip from '@/components/cls/DoneChip.vue';
import ExpireChip from '@/components/cls/ExpireChip.vue';

export const rules = {
  appId: [Rules.presence()],
  pattern: [
    Rules.presence(),
    Rules.format(/^[^/$]+\/[^$]*((\$[ADHN]\$)?[^$]?)*$/),
    Rules.format(/^[^\r\n]*$/, 'should not include a new line character'),
  ],
};

export function isAutoFormat(ua) {
  return /^[\w\-. ]+\/[\d.]+ CFNetwork\/[\d.]+ Darwin\/[\d.]+$/.test(ua);
}

export function isWildcardRulePattern(pattern) {
  return /\$[ADHN]\$/.test(pattern);
}

export default {
  components: {
    ExpireChip,
    DoneChip,
    WildcardsTable,
    TestPatternDialog,
    OwlDatePicker,
    AppDictSearch,
  },
  mixins: [ClassifyRowMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const pattern = this.type === 'bundle' ? this.item.bundle_id : this.item.user_agent;
    return {
      model: {
        pattern,
        app_dictionary_id: null,
        effective_start_date: null,
      },
      formattedPayload: {},
      rules: {
        appId: [],
      },
      editing: false,
      testPatternDialog: { show: false },
      domain: this.item.domain,
      countries: this.item.countries,
    };
  },
  computed: {
    isDisabled() {
      return this.processed || this.loading || this.isSelected;
    },
  },
  methods: {
    save() {
      this.rules = rules;
      this.formattedPayload = this.model;
      if (this.domain) {
        this.model.domain = this.domain;
      }
      if (this.type === 'ua' && this.countries && !this.isWildCardPattern()) {
        this.model.country = this.countries[0];
      }
      this.submitClassify(`/ios/${this.type}/discoveries/${this.item.id}/rules`, {
        success: () => {
          this.editing = false;
          this.$emit('expand', false);
          this.$emit('saved');
        },
      });
    },
    editPattern() {
      this.editing = !this.editing;
      if (!this.processed && !this.loading) {
        this.resetPattern();
      }
    },
    resetPattern() {
      this.model.pattern = this.item.user_agent;
    },
    onSave() {
      if (this.isWildCardPattern()) {
        this.testPatternDialog.show = true;
      } else {
        this.save();
      }
    },
    autoCreate(ua) {
      this.model.pattern = ua.replace(
        /\/[\d.]+ CFNetwork\/[\d.]+ Darwin\/[\d.]+$/,
        '/$D$ CFNetwork/$D$ Darwin/$D$',
      );
    },
    isAutoFormat,
    isWildCardPattern() {
      return this.type === 'ua' && isWildcardRulePattern(this.model.pattern);
    },
  },
};
</script>

<style scoped lang="scss">
.theme--dark.v-data-table tbody tr.elevation-3 {
  &:hover {
    background-color: transparent !important;
  }

  .row:first-child {
    border-bottom: thin solid rgba(255, 255, 255, 0.12);
  }
}
</style>
