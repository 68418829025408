<template>
  <v-simple-table>
    <thead>
      <tr>
        <th>Attribute</th>
        <th>Previous</th>
        <th></th>
        <th>Proposal</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(val, key) in proposal.previous_attributes" :key="key">
        <th>{{ key === 'app_dictionary_id' ? 'Application' : key | humanize }}</th>
        <td>
          <span v-if="key === 'app_dictionary_id' && prevApp" class="text-subtitle-1">
            {{ prevApp.name }}
          </span>
          <kbd v-else>{{ val + '' }}</kbd>
        </td>
        <td>
          <v-icon dense>mdi-arrow-right</v-icon>
        </td>
        <td>
          <span v-if="key === 'app_dictionary_id' && propApp" class="text-subtitle-1">
            {{ propApp.name }}
          </span>
          <kbd v-else>{{ proposal.proposed_attributes[key] + '' }}</kbd>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import SEARCH from '@/api/owl-search';

export default {
  props: {
    proposal: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    apps: [],
  }),
  computed: {
    prevApp() {
      return this.apps.find(el => el.id === this.proposal.previous_attributes.app_dictionary_id);
    },
    propApp() {
      return this.apps.find(el => el.id === this.proposal.proposed_attributes.app_dictionary_id);
    },
  },
  created() {
    if (
      Object.prototype.hasOwnProperty.call(this.proposal.proposed_attributes, 'app_dictionary_id')
    ) {
      this.fetchApps();
    }
  },
  methods: {
    fetchApps() {
      // TODO: add country code
      SEARCH.get('/apps/find', {
        params: {
          id: `${this.proposal.previous_attributes.app_dictionary_id},${this.proposal.proposed_attributes.app_dictionary_id}`,
        },
      }).then(({ data }) => {
        this.apps = data;
      });
    },
  },
};
</script>
